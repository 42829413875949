@import "styles";
@import "pygments-pastie";

footer a {
  color: $gray-900;

  &:focus,
  &:hover {
    color: $primary;
  }
}

a {
  color: $primary;
}
